import React, { ReactNode } from 'react';

// Define props interfaces for each component
interface InfoBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

interface InfoBlockIconProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

interface InfoBlockContentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

interface InfoBlockLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: ReactNode;
  className?: string;
}

interface InfoBlockDescriptionProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  children: ReactNode;
  className?: string;
}

// Root component
const InfoBlock: React.FC<InfoBlockProps> & {
  Icon: React.FC<InfoBlockIconProps>;
  Content: React.FC<InfoBlockContentProps>;
  Label: React.FC<InfoBlockLabelProps>;
  Description: React.FC<InfoBlockDescriptionProps>;
} = ({ children, className = '', ...props }) => {
  return (
    <div
      className={`grid grid-cols-[auto_1fr] auto-rows-auto gap-x-2 gap-y-1 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

// Icon component
const InfoBlockIcon: React.FC<InfoBlockIconProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <div className={`row-span-2 text-slate-700 ${className}`} {...props}>
      {children}
    </div>
  );
};

// Content component
const InfoBlockContent: React.FC<InfoBlockContentProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <div className={`col-start-2 leading-none ${className}`} {...props}>
      {children}
    </div>
  );
};

// Label component
const InfoBlockLabel: React.FC<InfoBlockLabelProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <span
      className={`font-medium text-[0.875rem] text-slate-700 ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};

// Description component
const InfoBlockDescription: React.FC<InfoBlockDescriptionProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <span
      className={`font-medium text-[0.75rem] text-slate-500 ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};

// Compose the components
InfoBlock.Icon = InfoBlockIcon;
InfoBlock.Content = InfoBlockContent;
InfoBlock.Label = InfoBlockLabel;
InfoBlock.Description = InfoBlockDescription;

export { InfoBlock };
