import css from 'styles/Team.module.scss'
import Markdown from 'components/elements/Markdown'
import { Section } from 'components/Sections'
import { ComponentSectionsTeam } from '__generated__/schema.graphql.types'
import StrapiImage from 'components/shared/Image'
import { FC } from 'react'
import { motion, Variants } from 'framer-motion'

interface TeamProps extends Section {
  data: ComponentSectionsTeam
}

const parentVariants: Variants = {
  visible: {
    transition: {
      delayChildren: 0.25,
      staggerChildren: 0.075,
    }
  },
  hidden: {}
}

const childVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  },
  hidden: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.3,
      ease: 'easeOut'
    }
  }
}

const Team: FC<TeamProps> = ({ data }) => {
  const { title, body, teamMembers } = data

  return (
    <div className={css.container}>
      <section>
        <h2>{title}</h2>
        {body && <Markdown content={body} />}
        <motion.div
          variants={parentVariants}
          initial="hidden"
          animate="visible"
          className={css.team}
        >
          {teamMembers?.data.map(member => {
            const attributes = member?.attributes
            const avatar = attributes?.avatar
            const name = attributes?.name
            const role = attributes?.role
            const phone = attributes?.phone
            const info = attributes?.info
            const email = attributes?.email

            return (
              <motion.article
                key={member.id}
                variants={childVariants}
                className={css.member}
              >
                <div className={css.avatar}>
                  {avatar && (
                    <StrapiImage
                      strapi={avatar}
                      fill
                    />
                  )}
                </div>
                <h3>{name}</h3>
                {role && <p className={css.role}>{role}</p>}
                {email && <p>{email}</p>}
                {phone && <p>{phone}</p>}
                {info && <Markdown content={info} />}
              </motion.article>
            )
          })}
        </motion.div>
      </section>
    </div>
  );
}

export default Team
