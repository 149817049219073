import { z } from "zod";

export const CreateBookingInput = z.object({
  name: z.string(),
  email: z.string().email(),
  organization: z.string().optional(),
  location: z.coerce.number().int(),
  phone: z.string(),
  products: z.coerce.number().int().gte(1),
  additionalInformation: z.string().optional()
})

export type CreateBookingInput = z.infer<typeof CreateBookingInput>;
