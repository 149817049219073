'use client';

import {
  Error,
  NotificationEntity,
  NotificationRelationResponseCollection,
  PageSectionsDynamicZone,
  Product,
} from '__generated__/schema.graphql.types';
import { FC } from 'react';

import Hero from './sections/Hero';
import CallToAction from './sections/CallToAction';
import VisitMap from './sections/VisitMap';
import VisitStatistics from './sections/VisitStatistics';
import VillageOverview from './sections/VillageOverview';
import Testimonials from './sections/Testimonials';
import Clients from './sections/Clients';
import ProductHeader from './sections/ProductHeader';
import ProductUserBenefits from './sections/ProductUserBenefits';
import ProductPlatformBenefits from './sections/ProductPlatformBenefits';
import ProductTeacherBenefits from './sections/ProductTeacherBenefits';
import ProductCallToAction from './sections/ProductCallToAction';
import ColumnSingle from './sections/ColumnSingle';
import ColumnDouble from './sections/ColumnDouble';
import MediaWithCaption from './sections/MediaWithCaption';
import Team from './sections/Team';
import FocusTopics from './sections/FocusTopics';
import Locations from './sections/Locations';
import BookingForm from './sections/BookingForm';
import { Maybe } from 'graphql/jsutils/Maybe';
import TrainingsHero from './sections/trainings/Hero';
import { TrainingsIntroduction } from './sections/trainings/Introduction';
import { TrainingsAssociatedGame } from './sections/trainings/AssociatedGame';
import { TrainingsTestimonials } from './sections/trainings/Testimonials';
import { BookingOptions } from './sections/BookingOptions';

interface IIndexable {
  [key: string]: any;
}

const sectionsPrefix = 'ComponentSections';

// Map Strapi sections to section components
const sectionComponents: IIndexable = {
  Hero,
  CallToAction,
  VisitMap,
  VisitStatistics,
  VillageOverview,
  Testimonials,
  Clients,
  ProductHeader,
  ProductUserBenefits,
  ProductPlatformBenefits,
  ProductTeacherBenefits,
  ProductCallToAction,
  ColumnSingle,
  ColumnDouble,
  MediaWithCaption,
  Team,
  FocusTopics,
  Locations,
  BookingForm,
  TrainingsHero,
  TrainingsIntroduction,
  TrainingsAssociatedGame,
  TrainingsTestimonials,
  BookingOptions,
};

// TODO One day refactor to use the same kind of dynamic zone typing as navbar.
const mappedSectionComponents = Object.fromEntries(
  Object.keys(sectionComponents).map((key) => {
    return [`${sectionsPrefix}${key}`, sectionComponents[key]];
  }),
);

export interface Section {
  sectionData: PageSectionsDynamicZone;
  associatedProduct?: Maybe<Product>;
  pageAccentColor?: string;
  notifications?: NotificationEntity[];
  position: number;
}

// Display a section individually
const Section: FC<Section> = ({
  pageAccentColor,
  associatedProduct,
  sectionData,
  notifications,
  position,
}) => {
  if (typeof sectionData.__typename === 'undefined') {
    console.warn(
      'Encountered a section with __typename undefined, returning null.',
    );
    return null;
  }

  // Prepare the component
  const SectionComponent = mappedSectionComponents[sectionData.__typename];

  if (!SectionComponent) {
    console.warn(
      `Attempting to use Section with __typename ${sectionData.__typename}, but no matching component found!`,
    );
    return null;
  }

  // Display the section
  return (
    <SectionComponent
      pageAccentColor={pageAccentColor}
      associatedProduct={associatedProduct}
      notifications={notifications}
      data={sectionData}
      position={position}
    />
  );
};

interface SectionsProps {
  sections?: Maybe<PageSectionsDynamicZone>[];
  associatedProduct?: Maybe<Product>;
  pageAccentColor?: string;
  notifications: Maybe<NotificationRelationResponseCollection>;
  preview: boolean;
}

// Display the list of sections
const Sections: FC<SectionsProps> = ({
  associatedProduct,
  pageAccentColor,
  notifications,
  sections,
  preview,
}) => {
  if (typeof sections === 'undefined' || sections === null) {
    return <div>test</div>;
  }

  const accentColor = associatedProduct?.accentColor ?? pageAccentColor;

  return (
    <>
      {sections.map((section, index) => {
        if (section?.__typename === 'Error') {
          return <div key={index}>test</div>;
        }

        if (typeof section === 'undefined' || section === null) {
          return <div key={index}>test</div>;
        }

        return (
          <Section
            key={`${section!.__typename}${
              (section as Exclude<PageSectionsDynamicZone, Error>).id
            }`}
            sectionData={section}
            position={index}
            notifications={notifications?.data}
            associatedProduct={associatedProduct}
            pageAccentColor={pageAccentColor}
          />
        );
      })}
      <style jsx global>{`
        ::selection {
          color: white;
          background-color: ${accentColor ?? '#3AA0FF'};
        }

        :root {
          --nav-accent-color: ${accentColor ?? '#32B300'};
        }
      `}</style>
    </>
  );
};

export default Sections;
