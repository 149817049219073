import Markdown from '../elements/Markdown';
import cn from 'classnames';

import css from 'styles/ProductCallToAction.module.scss';
import { FC } from 'react';
import {
  ComponentSectionsProductCallToAction,
  Maybe,
  Product,
} from '__generated__/schema.graphql.types';
import ListItemWithIcon, {
  ListItemWithIconProps,
} from 'components/elements/ListItemWithIcon';
import { Section } from 'components/Sections';
import Button from 'components/elements/Button';
import hexRgb from 'hex-rgb';

interface ProductCallToActionProps extends Section {
  data: ComponentSectionsProductCallToAction;
}

const getListItems = (associatedProduct?: Maybe<Product>) => {
  let listItems: ListItemWithIconProps[] = [];

  if (!!associatedProduct?.duration) {
    listItems = listItems.concat({
      title: 'Kestus',
      body: `${associatedProduct.duration}`,
      icon: 'watch_later',
      color: '#fed233',
      coloredTitle: true,
    });
  }

  if (!!associatedProduct?.players) {
    listItems = listItems.concat({
      title: 'Mängijaid',
      body: `${associatedProduct.players}`,
      icon: 'people',
      color: '#32b300',
      coloredTitle: true,
    });
  }

  if (!!associatedProduct?.price_onsite && !!associatedProduct?.unit_onsite) {
    listItems = listItems.concat({
      title: 'Hind linnakus',
      body: `${associatedProduct.price_onsite} € / ${associatedProduct.unit_onsite}`,
      icon: 'euro',
      color: '#3aa0ff',
      coloredTitle: true,
    });
  }

  if (!!associatedProduct?.ageRange) {
    listItems = listItems.concat({
      title: 'Soovitatud vanus',
      body: `${associatedProduct.ageRange} a`,
      icon: 'emoji_emotions',
      color: '#d95aee',
      coloredTitle: true,
    });
  }

  return listItems;
};

const ProductTeacherBenefits: FC<ProductCallToActionProps> = ({
  associatedProduct,
  data,
}) => {
  const { title, body, links } = data;

  const listItems = getListItems(associatedProduct);

  const buttonColor = hexRgb(associatedProduct?.accentColor ?? '#3aa0ff');

  return (
    <div className={css.wrapper}>
      <aside className={css.listItems}>
        {listItems.map((item) => (
          <ListItemWithIcon
            className={cn(css.listItem)}
            direction="row"
            key={item?.title}
            {...item}
          />
        ))}
      </aside>
      <section className={css.section}>
        <h2>{title}</h2>
        <Markdown className={css.markdown} content={body ?? ''} />
        <div className={css.buttons}>
          {links && (
            <div className={css.buttons}>
              {links.map((link) => (
                <Button
                  key={link?.id}
                  href={link?.url ?? ''}
                  type={link?.type}
                  openInNew={link?.openInNew}
                  textColor={link?.type == 'inline' ? '#4E5D69' : 'white'}
                  normalColor={`rgba(${buttonColor.red}, ${buttonColor.green}, ${buttonColor.blue}, 1)`}
                  hoverColor={`rgba(${buttonColor.red}, ${buttonColor.green}, ${buttonColor.blue}, 0.9)`}
                >
                  {link?.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ProductTeacherBenefits;
