import styles from 'styles/Clients.module.scss';
import StrapiImage from 'components/shared/Image';
import { Section } from 'components/Sections';
import { ComponentSectionsClients } from '__generated__/schema.graphql.types';
import { FC } from 'react';

interface ClientsProps extends Section {
  data: ComponentSectionsClients;
}

const Clients: FC<ClientsProps> = ({
  data,
  pageAccentColor,
  associatedProduct,
}) => {
  const { title, clients } = data;
  const accentColor =
    associatedProduct?.accentColor ?? pageAccentColor ?? '#3AA0FF';

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h2 style={{ color: accentColor }}>{title}</h2>
        <div className={styles.clients}>
          {clients?.data.map((client) => {
            const { width, height } =
              client.attributes?.logo.data?.attributes ?? {};
            return (
              <div
                key={client.id}
                className={styles.logoContainer}
              >
                {client?.attributes?.logo && (
                  <StrapiImage
                    strapi={client.attributes.logo}
                    width={width ?? 0}
                    height={height ?? 0}
                    sizes="100vw"
                  />
                )}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Clients;
