'use client';
import StrapiImage from 'components/shared/Image';
import Markdown from 'components/elements/Markdown';

import { FC } from 'react';
import {
  ComponentSectionsTrainingsHero,
  Product,
} from '__generated__/schema.graphql.types';
import { Timer, UserCheck, Users } from 'lucide-react';
import { InfoBlock } from 'components/shared/InfoBlock';
import { Separator } from '@/components/ui/separator';
import { Button } from 'components/ui/button';
import Link from 'next/link';

interface HeaderProps {
  data: ComponentSectionsTrainingsHero;
  pageAccentColor?: string;
  associatedProduct: Product;
}

const InfoBlocks = ({ product }: { product: Product }) => {
  return (
    <>
      {product?.duration ? (
        <InfoBlock>
          <InfoBlock.Icon>
            <Timer />
          </InfoBlock.Icon>
          <InfoBlock.Content>
            <InfoBlock.Label>{product.duration}</InfoBlock.Label>
          </InfoBlock.Content>
          <InfoBlock.Content>
            <InfoBlock.Description className="uppercase">
              Kestus
            </InfoBlock.Description>
          </InfoBlock.Content>
        </InfoBlock>
      ) : null}
      {product?.players ? (
        <InfoBlock>
          <InfoBlock.Icon>
            <Users />
          </InfoBlock.Icon>
          <InfoBlock.Content>
            <InfoBlock.Label>{product.players}</InfoBlock.Label>
          </InfoBlock.Content>
          <InfoBlock.Content>
            <InfoBlock.Description className="uppercase">
              Osalejat
            </InfoBlock.Description>
          </InfoBlock.Content>
        </InfoBlock>
      ) : null}
      {product?.ageRange ? (
        <InfoBlock>
          <InfoBlock.Icon>
            <UserCheck />
          </InfoBlock.Icon>
          <InfoBlock.Content>
            <InfoBlock.Label>{product.ageRange}</InfoBlock.Label>
          </InfoBlock.Content>
          <InfoBlock.Content>
            <InfoBlock.Description className="uppercase">
              Soovitatud vanus
            </InfoBlock.Description>
          </InfoBlock.Content>
        </InfoBlock>
      ) : null}
    </>
  );
};

const TrainingsHero: FC<HeaderProps> = ({
  data,
  pageAccentColor,
  associatedProduct,
}) => {
  const { heading, kicker, description, images } = data;
  const accentColor =
    associatedProduct?.accentColor ?? pageAccentColor ?? '#3AA0FF';

  return (
    <>
      <section className="container py-32 pt-24 lg:pt-48 grid grid-cols-1 [grid-template-areas:'aside''main'] lg:[grid-template-areas:'main_aside'] lg:grid-cols-[1fr_1fr] gap-x-8 gap-y-12">
        <div className="space-y-12 [grid-area:main]">
          <div className="col-span-1">
            <div className="gap-y-1 mb-12">
              <h1 className="text-[2rem] sm:text-[2.5rem] break-all md:text-[3rem] my-0 text-slate-600 leading-none font-bold">
                {heading}
              </h1>
              <span
                className={`font-semibold uppercase text-[0.875rem]`}
                style={{ color: accentColor }}
              >
                {kicker}
              </span>
            </div>
            <Markdown
              content={description ?? ''}
              className="text-slate-500 max-w-2xl lg:max-w-lg leading-7 text-[1rem] space-y-2"
            />
          </div>
          <div className="flex flex-row flex-wrap gap-x-6 md:gap-x-12 gap-y-8">
            <InfoBlocks product={associatedProduct} />
          </div>
          <div className="grid sm:grid-flow-col sm:auto-cols-auto grid-flow-row  justify-stretch  gap-x-12 gap-y-4">
            <div className="grid grid-cols-[auto] justify-items-center gap-y-5">
              <Button
                className="justify-self-stretch bg-purple-200 text-purple-700 hover:bg-purple-100"
                asChild
              >
                <Link href="#broneeri">Broneeri linnakus</Link>
              </Button>
              <div className="flex flex-col text-center">
                <span className="font-medium text-slate-700 text-[1rem] leading-6">
                  al. {associatedProduct.price_onsite}€ /{' '}
                  {associatedProduct.unit_onsite}
                </span>
                <span className="uppercase font-semibold text-slate-500 text-[0.75rem] leading-5">
                  Linnakus
                </span>
              </div>
            </div>
            <Separator orientation="vertical" className="justify-self-center" />
            <div className="grid grid-cols-[auto] justify-items-center gap-y-5">
              <Button
                variant="outline"
                className="justify-self-stretch"
                asChild
              >
                <Link href="#broneeri">Broneeri väljasõit</Link>
              </Button>
              <div className="flex flex-col text-center">
                <span className="font-medium text-slate-700 text-[1rem] leading-6">
                  al. {associatedProduct.price_offsite}€ /{' '}
                  {associatedProduct.unit_onsite}
                </span>
                <span className="uppercase font-semibold text-slate-500 text-[0.75rem] leading-5">
                  Väljasõit soovitud asukohta
                </span>
              </div>
            </div>
          </div>
        </div>
        <aside className="[grid-area:aside]">
          <div className="grid grid-cols-2 grid-rows-5 grid-flow-col h-96 lg:h-full gap-6">
            <div className="row-span-3 col-span-1 relative overflow-hidden rounded-xl rounded-tl-[4rem] bg-slate-100">
              {images.data?.[0] ? (
                <StrapiImage
                  fill
                  style={{ objectFit: 'cover' }}
                  strapi={images.data[0]}
                />
              ) : null}
            </div>
            <div className="row-span-2 col-span-1 relative overflow-hidden rounded-xl rounded-bl-[4rem] bg-slate-100">
              {images.data?.[1] ? (
                <StrapiImage
                  fill
                  style={{ objectFit: 'cover' }}
                  strapi={images.data[1]}
                />
              ) : null}
            </div>
            <div className="row-span-5 col-span-1 relative overflow-hidden rounded-xl rounded-r-[4rem] bg-slate-100">
              {images.data?.[2] ? (
                <StrapiImage
                  fill
                  style={{ objectFit: 'cover' }}
                  strapi={images.data[2]}
                />
              ) : null}
            </div>
          </div>
        </aside>
      </section>
    </>
  );
};

export default TrainingsHero;
