
import Markdown from 'components/elements/Markdown'
import cn from 'classnames'

import styles from 'styles/ProductPlatformBenefits.module.scss'
import { ComponentElementsProductPlatformBenefit, ComponentSectionsProductPlatformBenefits } from '__generated__/schema.graphql.types'
import ParallaxCard from 'components/elements/ParallaxCard'
import { getStrapiMedia } from 'lib/assets'
import { FC, useState } from 'react'

import { motion } from 'framer-motion'
import useMediaQuery from 'hooks/useMediaQuery'

const paths = [
  'M0,128L80,149.3C160,171,320,213,480,213.3C640,213,800,171,960,149.3C1120,128,1280,128,1360,128L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,32L80,64C160,96,320,160,480,192C640,224,800,224,960,192C1120,160,1280,96,1360,64L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,64L80,74.7C160,85,320,107,480,144C640,181,800,235,960,240C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,160L80,149.3C160,139,320,117,480,122.7C640,128,800,160,960,149.3C1120,139,1280,85,1360,58.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,96L80,85.3C160,75,320,53,480,74.7C640,96,800,160,960,176C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,192L80,170.7C160,149,320,107,480,112C640,117,800,171,960,181.3C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
]

interface ProductPlatformBenefitsProps {
  data: ComponentSectionsProductPlatformBenefits
}

interface BenefitListItemProps {
  data: ComponentElementsProductPlatformBenefit
  cardSide: 'left' | 'right'
}

const BenefitListItem: FC<BenefitListItemProps> = ({ data, cardSide='right' }) => {
  const { title, body, color, media } = data

  const parallaxCard = (
    <ParallaxCard
      url={getStrapiMedia(media?.data?.attributes?.url ?? '') ?? ''}
      type='video'
      color={color ?? '#3aa0ff'}
      side={cardSide}
      className={styles.parallaxCard}
    />
  )

  return (
    <article className={cn(styles.benefitListItem, {[styles.left]: cardSide == 'left'})}>
      {cardSide === 'left' ? parallaxCard : null}
      <section>
        <h3 style={{ color: color ?? '#3aa0ff' }}>{title}</h3>
        <Markdown className={styles.markdown} content={body ?? ''} />
      </section>
      {cardSide === 'right' ? parallaxCard : null}
    </article>
  )

}

const ProductPlatformBenefits: FC<ProductPlatformBenefitsProps> = ({ data }) => {
  const { kicker, title, body, benefits } = data

  const startingTopPath = Math.floor(Math.random() * paths.length)
  const startingBottomPath = Math.floor(Math.random() * paths.length)

  // wave
  const [topPath, setTopPath] = useState(startingTopPath)
  const [nextTopPath, setNextTopPath] = useState(startingTopPath+1 >= paths.length ? 0 : startingTopPath+1)

  const [bottomPath, setBottomPath] = useState(startingBottomPath)
  const [nextBottomPath, setNextBottomPath] = useState(startingBottomPath+1 >= paths.length ? 0 : startingBottomPath+1)

  const isMobile = useMediaQuery('(max-width: 1200px)')

  return (
    <section className={styles.section}>
      <motion.div className={cn(styles.wave, styles.top)}>
        <motion.svg viewBox={'0 0 1440 320'}>
          <motion.path
            style={{ fill: '#001122' }}
            initial={{ d: paths[topPath] }}
            animate={{ d: paths[nextTopPath] }}
            onAnimationComplete={() => {
              setTopPath(nextTopPath)
              const nextIndex = nextTopPath+1 >= paths.length ? 0 : nextTopPath+1
              setNextTopPath(nextIndex)
            }}
            transition={{
              ease: 'easeInOut',
              duration: 10
            }}
          />
        </motion.svg>
      </motion.div>
      <div className={styles.content}>
        <span className={styles.kicker}>{kicker}</span>
        <h2>{title}</h2>
        <Markdown content={body ?? ''} wrapper='div' />

        <div className={styles.benefitList}>
          {benefits?.map((benefit, index) => {
            return benefit ? (
              <BenefitListItem
                key={benefit?.title}
                data={benefit}
                cardSide={(index % 2 || isMobile) ? 'left' : 'right'}
              />
            ) : null
          })}
        </div>

      </div>
      <motion.div className={cn(styles.wave, styles.bottom)}>
        <motion.svg viewBox={'0 0 1440 320'}>
          <motion.path
            style={{ fill: '#001122' }}
            initial={{ d: paths[bottomPath] }}
            animate={{ d: paths[nextBottomPath] }}
            onAnimationComplete={() => {
              setBottomPath(nextBottomPath)
              const nextIndex = nextBottomPath+1 >= paths.length ? 0 : nextBottomPath+1
              setNextBottomPath(nextIndex)
            }}
            transition={{
              ease: 'easeInOut',
              duration: 10
            }}
          />
        </motion.svg>
      </motion.div>
    </section>
  )
}

export default ProductPlatformBenefits
