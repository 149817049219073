import StrapiImage from 'components/shared/Image';
import { ComponentSectionsTrainingsTestimonials } from '@/__generated__/schema.graphql.types';
import Markdown from '@/components/elements/Markdown';
import { FastAverageColor } from 'fast-average-color';
import { Suspense, use, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

export const TrainingsTestimonial = (
  testimonial: NonNullable<
    ComponentSectionsTrainingsTestimonials['testimonials']
  >['data'][number],
) => {
  const [color, setColor] = useState<[number, number, number]>([255, 255, 255]);

  // TODO: Replace with a way better solution
  useEffect(() => {
    const fac = new FastAverageColor();
    fac
      .getColorAsync(testimonial.attributes?.avatar.data?.attributes?.url ?? '')
      .then((_color) => {
        return setColor([_color.value[0], _color.value[1], _color.value[2]]);
      })
      .catch(() => {});
  }, []);

  const highlightColor = `rgb(${[color].join(',')})`;
  const fromColor = `rgba(${[...color, 0.05].join(',')})`;
  const toColor = `rgba(${[...color, 0.1].join(',')})`;

  return (
    <article
      key={testimonial.id}
      className={cn(
        'grid grid-cols-[min-content_auto] grid-rows-[auto_auto] gap-x-3 gap-y-6 bg-gradient-to-r from-[var(--fromColor)] to-[var(--toColor)] p-6 rounded-2xl',
        `selection:bg-[var(--highlightColor)]`,
      )}
      style={{
        // @ts-expect-error
        '--fromColor': fromColor,
        '--toColor': toColor,
        '--highlightColor': highlightColor,
      }}
    >
      <Markdown
        content={testimonial.attributes?.body ?? ''}
        className="text-[0.875rem] leading-6 text-slate-700 col-span-2"
      />
      <div className="relative size-12 rounded-lg overflow-hidden bg-slate-100">
        {testimonial.attributes?.avatar ? (
          <StrapiImage
            fill
            style={{ objectFit: 'cover' }}
            strapi={testimonial.attributes?.avatar}
          />
        ) : null}
      </div>
      <div>
        <h4 className="text-[0.875rem] leading-6 text-slate-700">
          {testimonial.attributes?.name}
        </h4>
        <p className="text-[0.875rem] leading-6 text-slate-500">
          {testimonial.attributes?.role}
        </p>
      </div>
    </article>
  );
};
