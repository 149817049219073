import styles from 'styles/ParallaxCard.module.scss'
import { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react'
import { motion, useAnimation, useMotionTemplate, useTime, useTransform } from 'framer-motion'
import cn from 'classnames'
import useMediaQuery from 'hooks/useMediaQuery'

interface ParallaxCardProps {
  url: string
  type: 'video' | 'image'
  color: string
  side: 'left' | 'right'
  className?: string
}

const easeInOut = (x: number): number => {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

const ParallaxCard: FC<ParallaxCardProps> = ({ url, type, color, side, className }) => {
  const container = useRef<HTMLDivElement>(null)
  const [parallaxStyle, setParallaxStyle] = useState<CSSProperties>()

  const isMobile = useMediaQuery('(max-width: 1200px)')

  const handleScroll = useCallback(() => {
    const card = container.current
    const rect = card?.getBoundingClientRect()
    const y = rect?.y ?? 0
    const height = rect?.height ?? 0

    const { innerHeight: winHeight } = window

    const yCenter = y + height / 2

    const winYCenter = winHeight / 2

    const yDelta = (winYCenter - yCenter)/winHeight*2
    const clampedYDelta = Math.abs(yDelta) > 1 ? Math.sign(yDelta) : yDelta
    const easedYDelta = easeInOut((clampedYDelta+1)/2)

    // X is vertical
    // Y is horizontal
    const maxAngleX = 5
    const maxAngleY = 10
    const calculatedX = (-maxAngleX*2 * easedYDelta) + maxAngleX
    const calculatedY = (side == 'right' ? -1 : 1) * maxAngleY * Math.abs(yDelta)

    const xOffset = 1.5

    if (isMobile) {
      setParallaxStyle({
        transform: `perspective(1500px) rotateX(${calculatedX}deg)`
      })
    } else {
      setParallaxStyle({
        transform: `perspective(1500px) translateX(${side == 'left' ? xOffset : -xOffset}rem) rotateX(${calculatedX}deg) rotateY(${side == 'left' ? maxAngleY : -maxAngleY}deg)`
      })
    }

  }, [side, isMobile])

  const time = useTime()
  const degTransform = useTransform(
    time,
    [0, 25 * 1000],
    [0, 360],
    { clamp: false }
  );

  const [deg, setDeg] = useState(degTransform.get())

  degTransform.on('change', latest => {
    setDeg(latest)
  })

  // const conicGradient = useMotionTemplate`conic-gradient(from var(--deg)deg at 50% 50%, #FFFFFFFF 1%, #ffffff00 100%)`;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return  () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll])

  return (
    <div
      ref={container}
      className={cn(styles.container, className)}
      style={parallaxStyle}
    >
      <div className={styles.videoContainer}>
        <video
          muted
          autoPlay
          loop
          src={url}
        />
        <motion.div
          className={styles.shine}
          style={{ backgroundImage: `conic-gradient(from ${deg}deg at 50% 50%, #FFFFFF00 80%, #FFFFFFCC 100%)` }}
        />
      </div>
      <div style={{
        backgroundColor: color,
        boxShadow: `1rem 0.5rem 2rem 1rem ${color ?? '#3aa0ff'}20`
      }} className={styles.background}></div>
    </div>
  )
}

export default ParallaxCard
