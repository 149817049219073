'use client';
import Markdown from 'components/elements/Markdown';

import { FC } from 'react';
import {
  ComponentSectionsBookingOptions,
  Product,
} from '__generated__/schema.graphql.types';
import { ArrowUpRight } from 'lucide-react';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import styles from 'styles/MaterialIcons.module.scss';
import { Separator } from '../ui/separator';
import { Button } from '../ui/button';

interface BookingOptionsProps {
  data: ComponentSectionsBookingOptions;
  pageAccentColor?: string;
  associatedProduct: Product;
}

export const BookingOptions: FC<BookingOptionsProps> = ({
  data,
  associatedProduct,
}) => {
  const {
    heading,
    body,
    booking_on_site,
    booking_off_site,
    booking_self_service,
  } = data;

  return (
    <div
      className="container py-16 lg:py-32 lg:pt-24 grid grid-cols-[1fr_1fr] gap-x-8 gap-y-12"
      id="broneeri"
    >
      <div className="flex flex-col items-center col-span-2 mb-8 text-center">
        <h2 className="text-[1.875rem] my-0 text-slate-700 leading-none font-semibold mb-6">
          {heading}
        </h2>
        <Markdown content={body ?? ''} className="text-slate-500 max-w-2xl" />
      </div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(25rem,1fr))] gap-x-8 gap-y-8 col-span-2 justify-center items-start">
        {booking_on_site ? (
          <article className="flex flex-col gap-y-12 bg-gradient-to-b from-[rgba(245,158,11,0.05)] to-[rgba(245,158,11,0.03)] p-8 pt-12 rounded-2xl selection:bg-amber-500">
            <h3 className="text-amber-900 text-[1.5rem] font-semibold leading-8 text-center">
              {booking_on_site.heading}
            </h3>
            {booking_on_site.reasons?.map((reason) => (
              <div
                key={reason?.id}
                className="grid grid-cols-1 grid-rows-[min-content_auto] lg:grid-cols-[min-content_auto] lg:auto-rows-auto gap-x-8"
              >
                <span
                  className={cn(
                    styles.materialIcons,
                    `relative col-span-1 row-span-2 mb-4 lg:mb-0 mx-auto lg:mx-0`,
                    'after:text-amber-300',
                    'before:bg-amber-100 rounded-xl overflow-hidden',
                  )}
                  style={{
                    // @ts-expect-error variables are unknown at compile time
                    '--size': '1.5rem',
                  }}
                />
                <style jsx>{`
                  span {
                    padding: calc(var(--size) + 0.5rem);
                  }
                  span:after {
                    content: '${reason?.icon}';
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  }
                  span:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                `}</style>
                <h4 className="text-amber-900 font-semibold leading-6 text-[1rem] text-center lg:text-left">
                  {reason?.title}
                </h4>
                <Markdown
                  content={reason?.body ?? ''}
                  className="text-amber-900 leading-7 font-normal text-center lg:text-left"
                />
              </div>
            ))}
            <Separator className="bg-amber-700 opacity-25" />
            <div className="flex flex-col text-center gap-y-6">
              <div className="flex flex-col gap-y-2">
                <p className="text-amber-900 font-semibold text-[1rem] leading-6">
                  {`${associatedProduct.price_onsite}€ / ${
                    associatedProduct.unit_onsite
                  }${booking_on_site.pricing_footnote ? '†' : ''}`}
                </p>
                <span className="text-amber-900 uppercase text-[0.75rem] font-semibold leading-5">
                  Linnakus
                </span>
              </div>
              <div>
                <Button
                  asChild
                  className="bg-amber-500 hover:bg-amber-600 font-semibold"
                >
                  <Link href="/broneeri">Broneeri linnakus</Link>
                </Button>
              </div>
              <div>
                {booking_on_site.pricing_footnote ? (
                  <span className="text-amber-700 text-[0.875rem] font-medium leading-6">
                    † {booking_on_site.pricing_footnote}
                  </span>
                ) : null}
              </div>
            </div>
          </article>
        ) : null}
        {booking_off_site ? (
          <article className="flex flex-col gap-y-12 bg-gradient-to-b from-[rgba(34,197,94,0.05)] to-[rgba(34,197,94,0.03)] p-8 pt-12 rounded-2xl selection:bg-green-500">
            <h3 className="text-green-900 text-[1.5rem] font-semibold leading-8 text-center">
              {booking_off_site.heading}
            </h3>
            {booking_off_site.reasons?.map((reason) => (
              <div
                key={reason?.id}
                className="grid grid-cols-1 grid-rows-[min-content_auto] lg:grid-cols-[min-content_auto] lg:auto-rows-auto gap-x-8"
              >
                <span
                  className={cn(
                    styles.materialIcons,
                    `relative col-span-1 row-span-2 mb-4 lg:mb-0 mx-auto lg:mx-0`,
                    'after:text-green-300',
                    'before:bg-green-100 rounded-xl overflow-hidden',
                  )}
                  style={{
                    // @ts-expect-error variables are unknown at compile time
                    '--size': '1.5rem',
                  }}
                />
                <style jsx>{`
                  span {
                    padding: calc(var(--size) + 0.5rem);
                  }
                  span:after {
                    content: '${reason?.icon}';
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  }
                  span:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                `}</style>
                <h4 className="text-green-900 font-semibold leading-6 text-[1rem] text-center lg:text-left">
                  {reason?.title}
                </h4>
                <Markdown
                  content={reason?.body ?? ''}
                  className="text-green-900 leading-7 font-normal text-center lg:text-left"
                />
              </div>
            ))}
            <Separator className="bg-green-700 opacity-25" />
            <div className="flex flex-col text-center gap-y-6">
              <div className="flex flex-col gap-y-2">
                <p className="text-green-900 font-semibold text-[1rem] leading-6">
                  {`al. ${associatedProduct.price_offsite}€ / ${
                    associatedProduct.unit_offsite
                  }${booking_off_site.pricing_footnote ? '†' : ''}`}
                </p>
                <span className="text-green-900 uppercase text-[0.75rem] font-semibold leading-5">
                  Väljasõit soovitud asukohta
                </span>
              </div>
              <div>
                <Button
                  asChild
                  className="bg-green-500 hover:bg-green-600 font-semibold"
                >
                  <Link href="/broneeri">Saada päring</Link>
                </Button>
              </div>
              <div>
                {booking_off_site.pricing_footnote ? (
                  <span className="text-green-700 text-[0.875rem] font-medium leading-6">
                    † {booking_off_site.pricing_footnote}
                  </span>
                ) : null}
              </div>
            </div>
          </article>
        ) : null}
        {booking_self_service ? (
          <article className="flex flex-col gap-y-12 bg-gradient-to-b from-[rgba(14,165,233,0.05)] to-[rgba(14,165,233,0.03)] p-8 pt-12 rounded-2xl selection:bg-sky-500">
            <h3 className="text-sky-900 text-[1.5rem] font-semibold leading-8 text-center">
              {booking_self_service.heading}
            </h3>
            {booking_self_service.reasons?.map((reason) => (
              <div
                key={reason?.id}
                className="grid grid-cols-1 grid-rows-[min-content_auto] lg:grid-cols-[min-content_auto] lg:auto-rows-auto gap-x-8"
              >
                <span
                  className={cn(
                    styles.materialIcons,
                    `relative col-span-1 row-span-2 mb-4 lg:mb-0 mx-auto lg:mx-0`,
                    'after:text-sky-300',
                    'before:bg-sky-100 rounded-xl overflow-hidden',
                  )}
                  style={{
                    // @ts-expect-error variables are unknown at compile time
                    '--size': '1.5rem',
                  }}
                />
                <style jsx>{`
                  span {
                    padding: calc(var(--size) + 0.5rem);
                  }
                  span:after {
                    content: '${reason?.icon}';
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  }
                  span:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                `}</style>
                <h4 className="text-sky-900 font-semibold leading-6 text-[1rem] text-center lg:text-left">
                  {reason?.title}
                </h4>
                <Markdown
                  content={reason?.body ?? ''}
                  className="text-sky-900 leading-7 font-normal text-center lg:text-left"
                />
              </div>
            ))}
            <Separator className="bg-sky-700 opacity-25" />
            <div className="flex flex-col text-center gap-y-6">
              <div className="flex flex-col gap-y-2">
                <p className="text-sky-900 font-semibold text-[1rem] leading-6">
                  {booking_self_service.price}
                  {booking_self_service.pricing_footnote ? '†' : ''}
                </p>
                <span className="text-sky-900 uppercase text-[0.75rem] font-semibold leading-5">
                  Stardikomplekt
                </span>
              </div>
              <div>
                <Button
                  asChild
                  variant="link"
                  className="text-sky-700 font-medium text-[1rem]"
                >
                  <Link href="/broneeri">
                    Loe lähemalt <ArrowUpRight className="ml-2 size-5" />
                  </Link>
                </Button>
              </div>
              <div>
                {booking_self_service.pricing_footnote ? (
                  <span className="text-sky-700 text-[0.875rem] font-medium leading-6">
                    † {booking_self_service.pricing_footnote}
                  </span>
                ) : null}
              </div>
            </div>
          </article>
        ) : null}
      </div>
    </div>
  );
};
