'use client';
import StrapiImage from 'components/shared/Image';
import Markdown from 'components/elements/Markdown';

import { FC, Fragment } from 'react';
import {
  ComponentSectionsTrainingsAssociatedGame,
  Product,
} from '__generated__/schema.graphql.types';
import { Separator } from '@/components/ui/separator';
import { Button } from 'components/ui/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import styles from 'styles/MaterialIcons.module.scss';

interface AssociatedGameProps {
  data: ComponentSectionsTrainingsAssociatedGame;
  pageAccentColor?: string;
  associatedProduct: Product;
}

export const TrainingsAssociatedGame: FC<AssociatedGameProps> = ({ data }) => {
  const { kicker, product, body, images, focus_topics } = data;

  return (
    <section className="container py-32 pt-24 grid grid-cols-auto grid-flow-row lg:grid-flow-col lg:grid-cols-[1fr_1fr] gap-x-8 gap-y-12">
      <aside className="grid grid-cols-2 lg:px-6 lg:py-16 gap-x-6 h-96">
        <div className="relative overflow-hidden rounded-xl rounded-tl-[4rem] bg-slate-100">
          {images.data?.[0] ? (
            <StrapiImage
              fill
              style={{ objectFit: 'cover' }}
              strapi={images.data[0]}
            />
          ) : null}
        </div>
        <div className="relative overflow-hidden rounded-xl rounded-bl-[4rem] bg-slate-100">
          {images.data?.[1] ? (
            <StrapiImage
              fill
              style={{ objectFit: 'cover' }}
              strapi={images.data[1]}
            />
          ) : null}
        </div>
      </aside>
      <div className="flex flex-col gap-y-8">
        <div className="flex flex-col gap-y-4">
          <span
            className={`font-semibold uppercase text-[0.875rem] text-purple-500`}
          >
            {kicker}
          </span>
          <h2 className="text-[1.875rem] my-0 text-slate-700 leading-none font-semibold">
            {product?.data?.attributes?.name}
          </h2>
          <Markdown
            content={body ?? ''}
            className="text-slate-500 max-w-lg leading-7 text-[1rem] space-y-2"
          />
          <div className="mt-6">
            <h4 className="text-[1rem] text-slate-700 leading-6 font-medium">
              Mängu põhiteemad
            </h4>
            <p className="text-[1rem] text-slate-500 leading-7 font-normal">
              Just need teemad on selles mängus peamised:
            </p>
          </div>
          <div className="flex flex-col lg:flex-row gap-x-4 lg:gap-x-8 items-start lg:items-center mt-3 lg:mt-6 gap-y-3 lg:gap-y-0">
            {focus_topics?.map((topic, index) => (
              <Fragment key={topic?.id}>
                <div
                  className="flex flex-row lg:flex-col items-center lg:items-start py-2 gap-x-3 gap-y-6"
                  key={topic?.id}
                >
                  <div className="flex gap-2.5 justify-center items-center px-2 rounded-xl bg-slate-100 h-[42px] min-h-[42px] w-[42px]">
                    <span
                      className={cn(
                        styles.materialIcons,
                        `col-span-1 row-span-2`,
                      )}
                      style={{
                        // @ts-expect-error variables are unknown at compile time
                        '--size': '1.25rem',
                      }}
                    />
                    <style jsx>{`
                      span {
                        position: relative;
                      }
                      span:after {
                        content: '${topic?.icon}';
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                      }
                      span:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0.15;
                        border-radius: 0.75rem;
                      }
                    `}</style>
                  </div>
                  <h5 className="my-0 text-[1rem] font-medium leading-none text-slate-500">
                    {topic?.title}
                  </h5>
                </div>
                {index < focus_topics.length - 1 ? (
                  <Separator
                    orientation="vertical"
                    className="scale-y-75 bg-slate-100 hidden lg:block"
                  />
                ) : null}
              </Fragment>
            ))}
          </div>
          <div className="mt-5">
            <Button
              asChild
              className="bg-purple-200 text-purple-700 hover:bg-purple-100"
            >
              <Link
                href={
                  product?.data?.attributes?.page?.data?.attributes?.slug ?? ''
                }
              >
                Loe mängust lähemalt
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
