import { useMemo, useEffect, FC } from 'react';
import Gradient from '../elements/Gradient';
import StrapiImage from 'components/shared/Image';

import styles from '../../styles/Hero.module.scss';
import { getStrapiMedia } from 'lib/assets';
import { ComponentSectionsHero } from '__generated__/schema.graphql.types';
import { Section } from 'components/Sections';
import Markdown from 'components/elements/Markdown';
import { useIsClient, useMediaQuery } from 'usehooks-ts';
import Button from 'components/elements/Button';
import hexRgb from 'hex-rgb';
import { Notification } from 'components/elements/Notification';

interface BlobBubbleProps {
  background: string;
  icon: string;
  title: string;
  color: string;
}

const BlobBubble = ({ background, icon, title, color }: BlobBubbleProps) => {
  return (
    <div
      className={styles.blobBubble}
      style={{ backgroundImage: `url('${getStrapiMedia(background)}')` }}
    >
      <span className={styles.materialIcons}></span>
      <style jsx>{`
        span:after {
          color: ${color};
          content: '${icon}';
        }
      `}</style>
      <p style={{ color: `${color}` }}>{title}</p>
    </div>
  );
};

interface HeroProps extends Section {
  data: ComponentSectionsHero;
}

const Hero: FC<HeroProps> = ({ data, notifications, pageAccentColor }) => {
  // Create your instance
  const gradient = useMemo(() => new Gradient(), []);

  useEffect(() => {
    // Call `initGradient` with the selector to your canvas
    // @ts-ignore
    gradient.initGradient('#gradient-canvas');
  }, [gradient]);

  const { heading, kicker, body, heroBlob, links } = data;

  const combineHeading = useMediaQuery('(max-width: 975px)');
  const isClient = useIsClient();

  const headingText =
    combineHeading && isClient
      ? heading
      : heading
          .split(' ')
          .map((word: string, index: number) => (
            <span key={index}>{word.toUpperCase()}</span>
          ));

  const buttonColor = hexRgb('#32B300');

  return (
    <header className={styles.hero}>
      <canvas
        id="gradient-canvas"
        className={styles.gradientCanvas}
        data-transition-in
      ></canvas>
      <div className={styles.grid}>
        <div className={styles.content}>
          <span className={styles.kicker}>{kicker}</span>
          <h1>{headingText}</h1>
          {body && <Markdown content={body} />}
          <div className={styles.buttons}>
            {links?.map((link) => (
              <Button
                key={link?.id}
                href={link?.url ?? ''}
                type={link?.type}
                openInNew={link?.openInNew}
                textColor={link?.type == 'inline' ? '#4E5D69' : 'white'}
                normalColor={`rgba(${buttonColor.red}, ${buttonColor.green}, ${buttonColor.blue}, 1)`}
                hoverColor={`rgba(${buttonColor.red}, ${buttonColor.green}, ${buttonColor.blue}, 0.9)`}
              >
                {link?.label}
              </Button>
            ))}
          </div>
          <div className={styles.notifications}>
            {notifications?.map((notification) => {
              if (
                typeof notification?.attributes === 'undefined' ||
                notification.attributes === null
              ) {
                return null;
              }

              const { start, end } = notification.attributes;

              const startTs = new Date(start);
              const endTs = new Date(end);

              const now = new Date();

              if (now >= startTs && now < endTs) {
                return (
                  <Notification
                    {...notification}
                    key={notification.id}
                    pageAccentColor={pageAccentColor ?? '#3aa0ff'}
                  />
                );
              }

              return null;
            })}
          </div>
        </div>
        <div className={styles.video}>
          <div className={styles.blobContainer}>
            {heroBlob?.background && (
              <StrapiImage
                strapi={heroBlob.background}
                className={styles.bgBlob}
                priority
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center',
                  pointerEvents: 'none',
                }}
              />
            )}
            {heroBlob?.placeholder && (
              <StrapiImage
                strapi={heroBlob.placeholder}
                priority
                fill
                sizes="100vw"
                style={{
                  pointerEvents: 'none',
                  objectFit: 'contain',
                  objectPosition: 'center',
                }}
              />
            )}
            <div className={styles.blobWrapper}>
              <div className={styles.blobs}>
                {heroBlob?.bubbles?.map((bubble: any, index: number) => {
                  return (
                    <BlobBubble
                      key={index}
                      icon={bubble.icon}
                      title={bubble.title}
                      background={bubble.background.data.attributes.url}
                      color={bubble.color}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;
