import { FC } from 'react';
import { NotificationEntity } from '__generated__/schema.graphql.types';
import Markdown from './Markdown';
import Link from 'next/link';
import css from '../../styles/Notification.module.scss';
import hexRgb from 'hex-rgb';

interface NotificationProps extends NotificationEntity {
  pageAccentColor: string;
}

const Notification: FC<NotificationProps> = ({
  attributes,
  pageAccentColor,
}) => {
  if (attributes === null || typeof attributes === 'undefined') {
    return null;
  }

  const { title, body, icon, link } = attributes;

  const color = attributes?.accentColor ?? pageAccentColor;
  const c = hexRgb(color);
  const rgb = `${c.red}, ${c.green}, ${c.blue}`;
  const boxShadow = `0px 28px 53px rgba(${rgb}, 0.07),
            0px 6.25417px 11.8382px rgba(0, 0, 0, 0.0417275)
           `;

  return (
    <Link
      href={link?.url ?? ''}
      className={css.notification}
      style={{ boxShadow }}
    >
      {icon && (
        <div className={css.iconContainer}>
          <span className={css.materialIcons}></span>
          <style jsx>{`
            span:after {
              content: '${icon}';
              --size: 2.5rem;
              color: ${color};
            }
          `}</style>
        </div>
      )}
      <h4 style={{ color }} className={css.title}>
        {title}
      </h4>
      <Markdown className={css.body} content={body ?? ''} />
      <div className={css.arrowContainer}>
        <span className={css.materialIcons}></span>
        <style jsx>{`
          span:after {
            content: 'arrow_forward';
            --size: 1.5rem;
            color: ${color};
          }
        `}</style>
      </div>
    </Link>
  );
};

export { Notification };
