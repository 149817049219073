import { ElementType, FC, PropsWithChildren } from 'react';
import * as _production from 'react/jsx-runtime';
import { unified } from 'unified';

import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeReact from 'rehype-react';

import cn from 'classnames';
import styles from 'styles/Markdown.module.scss';

const production = {
  Fragment: _production.Fragment,
  jsx: _production.jsx,
  jsxs: _production.jsxs,
};

interface Props extends PropsWithChildren {
  wrapper?: ElementType;
  className?: string;
  content: string;
}

const Markdown: FC<Props> = ({
  wrapper: Wrapper = 'div',
  className,
  content,
  children,
}) => {
  const Content = unified()
    .use(remarkParse)
    .use(remarkRehype)
    // @ts-expect-error
    .use(rehypeReact, production)
    .processSync(content).result;

  return (
    <Wrapper className={cn(styles.markdown, className)}>
      {Content}
      {children}
    </Wrapper>
  );
};

export default Markdown;
