import styles from 'styles/Testimonials.module.scss';
import Markdown from 'components/elements/Markdown';
import { Section } from 'components/Sections';
import {
  ComponentSectionsTestimonials,
  Maybe,
  UploadFileEntity,
} from '__generated__/schema.graphql.types';
import StrapiImage from 'components/shared/Image';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import hexRgb from 'hex-rgb';
import blobshape from 'blobshape';
import useSize from 'hooks/useSize';
import { motion } from 'framer-motion';

interface AvatarProps {
  image?: Maybe<UploadFileEntity>;
  color: string;
}

interface BlobOptions {
  growth: number;
  edges: number;
}

const blobOptions = {
  growth: 8,
  edges: 5,
};

interface ContainerSize {
  width: number;
  height: number;
}

const defaultContainerSize: ContainerSize = {
  width: 5.5 * 16,
  height: 5.5 * 16,
};

const generateBlob = (containerSize?: ContainerSize, options?: BlobOptions) => {
  const { width, height } = containerSize ?? defaultContainerSize;
  const { path } = blobshape({
    size: Math.max(width, height),
    ...blobOptions,
    ...options,
  });
  return path;
};

const generateBgBlob = (
  containerSize?: ContainerSize,
  options?: BlobOptions,
) => {
  return generateBlob(containerSize, options ?? { growth: 9, edges: 5 });
};

const TestimonialAvatar: FC<AvatarProps> = ({ image, color }) => {
  const attributes = image?.attributes;
  const width = attributes?.width;
  const height = attributes?.height;

  const size = 5.5 * 16;
  const [path, setPath] = useState<string>(generateBlob());
  const [nextPath, setNextPath] = useState<string>(generateBlob());

  const [bgPath, setBgPath] = useState<string>(generateBgBlob());
  const [nextBgPath, setNextBgPath] = useState<string>(generateBgBlob());

  const c = hexRgb(color);
  const rgb = `${c.red}, ${c.green}, ${c.blue}`;
  const dropShadow = `drop-shadow(0px 0px 8px rgba(${rgb}, 0.5))`;

  return (
    <div className={styles.avatarContainer}>
      <div
        className={styles.avatar}
        style={{ clipPath: `url("#path-${image?.id}")` }}
      >
        <motion.svg
          viewBox={`0 0 ${size} ${size}`}
          className={styles.svg}
          transition={{
            ease: 'linear',
            duration: 0.2,
          }}
        >
          <motion.clipPath
            id={`path-${image?.id}`}
            clipPathUnits="userSpaceOnUse"
          >
            <motion.path
              d={path}
              initial={{ d: path }}
              animate={{ d: nextPath }}
              transition={{
                ease: 'linear',
                duration: 3,
              }}
              onAnimationComplete={() => {
                setPath(nextPath);
                setNextPath(generateBlob());
              }}
            />
          </motion.clipPath>
        </motion.svg>
        {image && width && height && (
          <StrapiImage
            key={image.id}
            strapi={image}
            width={width}
            height={height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
      </div>
      <motion.svg
        className={styles.bgBlob}
        transition={{
          ease: 'linear',
          duration: 0.2,
        }}
        viewBox={`0 0 ${size} ${size}`}
        style={{ filter: dropShadow }}
      >
        <motion.path
          style={{
            fill: `${color}`,
          }}
          initial={{ d: bgPath }}
          animate={{ d: nextBgPath }}
          transition={{
            ease: 'linear',
            duration: 3,
          }}
          onAnimationComplete={() => {
            setBgPath(nextBgPath);
            setNextBgPath(generateBgBlob());
          }}
        />
      </motion.svg>
    </div>
  );
};

interface TestimonialsProps extends Section {
  data: ComponentSectionsTestimonials;
}

const Testimonials: FC<TestimonialsProps> = ({ data }) => {
  const testimonials = data?.testimonials?.data;

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        {testimonials?.map((testimonial) => {
          const attributes = testimonial?.attributes;

          const name = attributes?.name;
          const role = attributes?.role;
          const body = attributes?.body;
          const avatar = attributes?.avatar;

          const color = testimonial?.attributes?.color ?? '#3AA0FF';
          const c = hexRgb(color);
          const rgb = `${c.red}, ${c.green}, ${c.blue}`;
          const boxShadow = `0px 28px 53px rgba(${rgb}, 0.07),
            0px 6.25417px 11.8382px rgba(0, 0, 0, 0.0417275)
           `;

          return (
            <figure key={testimonial.id}>
              <TestimonialAvatar image={avatar?.data} color={color} />
              <div className={styles.content} style={{ boxShadow }}>
                <Markdown wrapper="blockquote" content={body ?? ''} />
                <figcaption>
                  <cite>{name}</cite>
                  <div>{role}</div>
                </figcaption>
              </div>
            </figure>
          );
        })}
      </section>
    </div>
  );
};

export default Testimonials;
