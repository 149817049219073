import Link from 'next/link'
import React, { CSSProperties, FC, ReactNode } from 'react'
import css from 'styles/Button.module.scss'
import cn from 'classnames'
import Color from 'color'

interface ButtonProps {
  href: string
  onClick?: (event: MouseEvent) => void
  children: ReactNode
  className?: string
  type?: 'primary' | 'secondary' | 'inline' | null
  openInNew?: boolean | null
  textColor?: string
  normalColor?: string
  hoverColor?: string
}

const Button: FC<ButtonProps> = ({
  className,
  href,
  children,
  type = 'primary',
  openInNew = false,
  textColor = 'inherit',
  normalColor = 'hsl(209, 100%, 61%)',
  hoverColor,
}) => {
  return (
    <Link
      href={href}
      style={{
        '--textColor': textColor,
        '--normalColor': normalColor,
        '--hoverColor': Color(normalColor).lighten(0.1),
      } as CSSProperties}
      className={cn(css.button, css[type ?? 'primary'], className)}
      target={openInNew ? '_blank' : '_self'}
    >
      {children}
    </Link>
  )
}

export default Button   
