'use client';
import Markdown from 'components/elements/Markdown';
import css from 'styles/BookingForm.module.scss';
import { Section } from 'components/Sections';
import { FC, useCallback, useEffect, useState } from 'react';
import { ComponentSectionsBookingForm } from '__generated__/schema.graphql.types';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { Label } from 'components/ui/label';
import cn from 'classnames';
import { Button } from 'components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateBookingInput } from 'graphql/validations/createBooking';
import { Loader2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog';

import find from 'lodash/find';
import includes from 'lodash/includes';

interface BookingFormProps extends Section {
  data: ComponentSectionsBookingForm;
}

const invalidClasses =
  'ring-2 ring-offset-2 ring-red-500 focus:ring-2 focus:ring-red-500';

const BookingForm: FC<BookingFormProps> = ({ data, position }) => {
  const { body, title, products, availableLocations } = data;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    getFieldState,
    resetField,
    getValues,
    watch
  } = useForm({
    resolver: zodResolver(CreateBookingInput),
  });

  const [submitError, setSubmitError] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {}, [errors, getFieldState]);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        if (submitError) {
          setSubmitError(false);
        }

        const res = await fetch('/api/createBooking', {
          method: 'POST',
          body: JSON.stringify({ variables: JSON.stringify(data) }),
        });

        if (res.ok === false) {
          setSubmitError(true);
          return;
        }

        setShowSuccessDialog(true);
      } catch (err) {
        console.error(err);
        setSubmitError(true);
      }
    },
    [submitError],
  );
  const productsSelect = register('products');
  const locationSelect = register('location');

  const productsState = getFieldState('products');
  const locationState = getFieldState('location');
  const emailState = getFieldState('email');

  const locationValue = watch('location');
  const productValue = watch('products');

  useEffect(() => {
    if ((locationValue && productValue) === false) {
      return;
    }

    const location = find(availableLocations?.data, { id: locationValue})
    const availableProducts = location?.attributes?.availableProducts?.data.map(product => product.id)

    if (!includes(availableProducts, `${productValue}`)) {
      setValue('products', null);
    }
  }, [productValue, locationValue, availableLocations, resetField, setValue])

  return (
    <div
      className={cn(css.container, 'pt-8', 'pb-32', 'px-4', {
        [css.first]: position === 0,
      })}
    >
      <section className={cn(css.section)}>
        <h2>{title}</h2>
        <Markdown content={body ?? ''} className={css.markdown} />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-flow-row auto-rows-auto gap-4 mt-8"
        >
          <div className="grid grid-flow-col auto-cols-fr gap-4">
            <div>
              <Label>Asukoht</Label>
              <Select
                required
                onValueChange={(value) => setValue('location', value)}
              >
                <SelectTrigger
                  {...locationSelect.ref}
                  className={
                    locationState.isDirty && locationState.invalid
                      ? invalidClasses
                      : ''
                  }
                >
                  <SelectValue placeholder="Asukoht" />
                </SelectTrigger>
                <SelectContent>
                  {availableLocations?.data?.map((location) => {
                    return (
                      location.id && (
                        <SelectItem key={location.id} value={location.id}>
                          {location.attributes?.name}
                        </SelectItem>
                      )
                    );
                  })}
                </SelectContent>
              </Select>
            </div>

            <div>
              <Label>Toode</Label>
              <Select
                required
                disabled={getValues('location') === undefined}
                name="products"
                value={productValue}
                onValueChange={(value) => setValue('products', value)}
              >
                <SelectTrigger
                  ref={productsSelect.ref}
                  className={
                    productsState.isDirty && productsState.invalid
                      ? invalidClasses
                      : ''
                  }
                >
                  <SelectValue asChild>
                    <span>{find(products?.data, { id: productValue})?.attributes?.name ?? "Toode"}</span>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {products?.data?.map((product) => {
                    const availableLocationIds = product.attributes?.availableLocations?.data.map(loc => {
                      return loc.id
                    })

                    return (
                      product.id && (
                        <SelectItem disabled={!includes(availableLocationIds, locationValue)} key={product.id} value={product.id}>
                          {product.attributes?.name}
                        </SelectItem>
                      )
                    );
                  }).filter(val => !!val)}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div>
            <Label htmlFor="name">Nimi</Label>
            <Input
              required
              type="text"
              id="name"
              placeholder="Nimi"
              {...register('name')}
            />
          </div>

          <div>
            <Label htmlFor="organization">Asutus</Label>
            <Input
              type="text"
              id="organization"
              placeholder="Asutus"
              {...register('organization')}
            />
          </div>

          <div>
            <Label htmlFor="email">E-post</Label>
            <Input
              required
              type="email"
              id="email"
              placeholder="Email"
              className={cn('invalid:ring-destructive', {
                [invalidClasses]: emailState.isDirty && emailState.invalid,
              })}
              {...register('email')}
            />
          </div>

          <div>
            <Label htmlFor="phone">Telefon</Label>
            <Input
              required
              type="phone"
              id="phone"
              placeholder="Telefon"
              {...register('phone')}
            />
          </div>

          <div className="grid w-full gap-1.5">
            <Label htmlFor="additionalInformation">Lisainfo</Label>
            <Textarea
              placeholder="Kommentaarid, küsimiused, mõtted"
              id="additionalInformation"
              {...register('additionalInformation')}
            />
          </div>

          <Button disabled={isSubmitting}>
            {isSubmitting ? (
              <Loader2 className="mr-2 h-6 w-6 animate-spin" stroke="white" />
            ) : (
              'Saada'
            )}
          </Button>

          <AlertDialog open={showSuccessDialog}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Broneering saadetud!</AlertDialogTitle>
                <AlertDialogDescription>
                  Saime sinu broneeringu ilusti kätte ja võtame varsti ühendust,
                  et broneering kinnitada.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogAction onClick={() => setShowSuccessDialog(false)}>
                  Ok
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <AlertDialog open={submitError}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Veateade</AlertDialogTitle>
                <AlertDialogDescription>
                  Midagi läks broneeringu saatmisel valesti, palun võta meiega
                  ühendust.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogAction onClick={() => setSubmitError(false)} variant="destructive">
                  Ok
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </form>
      </section>
    </div>
  );
};

export default BookingForm;
