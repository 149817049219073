import Markdown from '../elements/Markdown'

import styles from 'styles/ProductTeacherBenefits.module.scss'
import { FC } from 'react'
import { ComponentSectionsProductTeacherBenefits } from '__generated__/schema.graphql.types'
import ListItemWithIcon from 'components/elements/ListItemWithIcon'
import Button from 'components/elements/Button'
import { Section } from 'components/Sections'
import hexRgb from 'hex-rgb'

interface Props extends Section {
  data: ComponentSectionsProductTeacherBenefits
}

const ProductTeacherBenefits: FC<Props> = ({ data, pageAccentColor }) => {
  const { kicker, links, title, body, listItems } = data
  const buttonColor = hexRgb(pageAccentColor ?? '#3aa0ff')

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <span className={styles.kicker}>{kicker}</span>
        <h2>{title}</h2>
        <Markdown className={styles.markdown} content={body ?? ''} />
        <div className={styles.buttons}>
          {links?.map((link) => (
            <Button
              key={link?.id}
              href={link?.url ?? ''}
              openInNew={link?.openInNew}
              type={link?.type}
              textColor={link?.type == 'inline' ? '#4E5D69' : 'white'}
              normalColor={`rgba(${buttonColor.red}, ${buttonColor.green}, ${buttonColor.blue}, 1)`}
              hoverColor={`rgba(${buttonColor.red}, ${buttonColor.green}, ${buttonColor.blue}, 0.9)`}
            >
              {link?.label}
            </Button>
          ))}
        </div>
      </div>
      <div className={styles.listItems}>
        {listItems?.map(item => {
          return <ListItemWithIcon
            className={styles.listItem}
            direction='column'
            key={item?.title}
            {...item}
            color={item?.color ?? ''}
            coloredTitle={item?.coloredTitle ?? true}
            icon={item?.icon ?? ''}
          />;
        })}
      </div>
    </section>
  )
}

export default ProductTeacherBenefits

