import StrapiImage from 'components/shared/Image';
import Markdown from 'components/elements/Markdown'

import styles from 'styles/ProductUserBenefits.module.scss'
import { ComponentSectionsProductUserBenefits } from '__generated__/schema.graphql.types'
import { FC } from 'react'
import ListItemWithIcon from 'components/elements/ListItemWithIcon'
import useMediaQuery from 'hooks/useMediaQuery';
import { Section } from 'components/Sections';

interface ProductUserBenefitsProps extends Section {
  data: ComponentSectionsProductUserBenefits,
}

const ProductUserBenefits: FC<ProductUserBenefitsProps> = ({ pageAccentColor, data, associatedProduct }) => {
  const { media } = data
  const isMobile = useMediaQuery('(max-width: 450px)')
  const listItemDirection = isMobile ? 'column' : 'row'
  const accentColor = associatedProduct?.accentColor ?? pageAccentColor ?? '#3AA0FF'

  return (
    <section className={styles.section}>
      <div className={styles.grid}>
        <aside className={styles.aside}>
          {media?.data.map(image => (
            <div className={styles.imageContainer} key={image.attributes?.hash} >
              <StrapiImage
                strapi={image}
                fill
                sizes='100vw'
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center'
                }} /> 
            </div>
          ))}

        </aside>
        <div className={styles.content}>
          <span className={styles.kicker}>{data.kicker}</span>
          {/* TODO Change the default page color to a palette color or smth like that. */}
          <style jsx>{`
            span {
              color: ${accentColor};
            }
          `}</style>
          <h2>{data.title}</h2>
          <Markdown className={styles.markdown} content={data.body ?? ''} />
          {data.listItems?.map(item => 
            <ListItemWithIcon
              className={styles.listItem}
              key={item?.title}
              {...item}
              direction={listItemDirection}
              color={item?.color ?? ''}
              coloredTitle={item?.coloredTitle ?? true}
              icon={item?.icon ?? ''}
            />
          )}
        </div>
      </div> 
    </section>
  );
}

export default ProductUserBenefits
