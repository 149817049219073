import styles from 'styles/VisitMap.module.scss'
import { Section } from 'components/Sections'
import { ComponentSectionsVisitMap } from '__generated__/schema.graphql.types'
import { FC, useCallback, useRef, useState } from 'react'

import mapboxgl, { LngLatBoundsLike, LngLatLike } from 'mapbox-gl'
import { PathLayer } from '@deck.gl/layers/typed'
import { WebMercatorViewport } from '@deck.gl/core/typed'
import Deck from '@deck.gl/react/typed'
import Map, { ViewState }  from 'react-map-gl'

import { circOut, motion, useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

interface VisitMapProps extends Section {
  data: ComponentSectionsVisitMap
}

const paths = [
  'M0,128L80,149.3C160,171,320,213,480,213.3C640,213,800,171,960,149.3C1120,128,1280,128,1360,128L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,32L80,64C160,96,320,160,480,192C640,224,800,224,960,192C1120,160,1280,96,1360,64L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,64L80,74.7C160,85,320,107,480,144C640,181,800,235,960,240C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,160L80,149.3C160,139,320,117,480,122.7C640,128,800,160,960,149.3C1120,139,1280,85,1360,58.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,96L80,85.3C160,75,320,53,480,74.7C640,96,800,160,960,176C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,192L80,170.7C160,149,320,107,480,112C640,117,800,171,960,181.3C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
]

// Bounding box for Estonia
// const sw: LngLatLike = [21.600589, 57.786115]
// const ne: LngLatLike = [28.582786, 59.587042]
const sw: LngLatLike = [21.453384, 57.502828]
const ne: LngLatLike = [28.355857, 59.061806]
const bounds: LngLatBoundsLike = [sw, ne]

const VisitMap: FC<VisitMapProps> = ({ data }) => {
  const { kicker, title, mapboxStyleUrl, visitData } = data
  const calculateDistance = (sourceCoords: [number, number], targetCoords: [number, number]) => {
    const source = new mapboxgl.LngLat(sourceCoords[0], sourceCoords[1])
    const target = new mapboxgl.LngLat(targetCoords[0], targetCoords[1])

    const distance = source.distanceTo(target)
    return distance
  }

  // Spark HUB in Tartu
  const targetCoords = [26.728109, 58.3810116] as [number, number]

  const [mapIdle, setMapIdle] = useState(false)

  type CustomViewState = Partial<ViewState> & {
    bounds?: LngLatBoundsLike,
    fitBoundsOptions?: mapboxgl.FitBoundsOptions
  }

  // wave
  const startingPath = Math.floor(Math.random() * paths.length)
  const [path, setPath] = useState(startingPath)
  const [nextPath, setNextPath] = useState(startingPath+1 >= paths.length ? 0 : startingPath+1)

  const initialViewState: CustomViewState = {
    longitude: 25,
    latitude: 58.5,
    zoom: 7.5,
    pitch: 55,
    bearing: 0,
    bounds,
    padding: {
      top: 5,
      bottom: 10,
      left: 15,
      right: 15,
    },
    fitBoundsOptions: {
      padding: {
        top: 5,
        bottom: 10,
        left: 25,
        right: 25,
      },
      pitch: 60,
      bearing: 0
    }
  }

  const mapRef = useRef(null)
  const { scrollYProgress } = useScroll({ target: mapRef, offset: ["start end", "end end"] })
  const pitchMotionValue = useTransform(scrollYProgress, [0.2, 1], [35, 60], { ease: circOut })
  const opacity = useTransform(scrollYProgress, [0.2, 1], [0, 1], { ease: circOut })
  const [progress, setProgress] = useState(scrollYProgress.get())
  const [pitch, setPitch] = useState(pitchMotionValue.get())

  useMotionValueEvent(scrollYProgress, 'change', latest => {
    setProgress(latest)
  })


  useMotionValueEvent(pitchMotionValue, 'change', latest => {
    setPitch(latest)
    updateMapPitch()
  })


  const pathData = visitData.map((coords: Array<[number, number]>) => {
    return {
      path: coords,
      distance: 150*1000/calculateDistance(coords[0], targetCoords)
    }
  })

  const pathLayer = new PathLayer({
    id: 'path-layer',
    data: pathData,
    getPath: (d: any) => d.path,
    getColor: () => [58, 160, 255, 255*progress],
    getWidth: (d: any) => 500/d.distance*progress,
    jointRounded: true,
    capRounded: true,
    updateTriggers: {
      getColor: [progress],
      getWidth: [progress]
    }
  })

  const [viewState, setViewState] = useState<CustomViewState>(initialViewState)

  const updateMapPitch = useCallback(() => {
    setViewState({...viewState, pitch })
  }, [viewState, pitch])

  const handleResize = useCallback<({width, height}: Partial<DOMRect>) => void>(({ width, height }) => {
    const { latitude, longitude, zoom } = new WebMercatorViewport({ width, height }).fitBounds(bounds as any, { padding: initialViewState.padding })
    setViewState({ ...viewState, latitude, longitude, zoom, pitch: pitch })
  }, [initialViewState.padding, viewState, pitch ])

  return (
    <>
      <section className={styles.mapSection}>
        <motion.div className={styles.wave}>
          <motion.svg viewBox={'0 0 1440 320'}>
            <motion.path
              style={{ fill: '#001122' }}
              initial={{ d: paths[path] }}
              animate={{ d: paths[nextPath] }}
              onAnimationComplete={() => {
                setPath(nextPath)
                const nextIndex = nextPath+1 >= paths.length ? 0 : nextPath+1
                setNextPath(nextIndex)
              }}
              transition={{
                ease: 'easeInOut',
                duration: 10
              }}
            />
          </motion.svg>
        </motion.div>
        <div className={styles.header}>
          <span className={styles.kicker}>{kicker}</span>
          <h1 className={styles.title}>{title}</h1>
        </div>
        <motion.div
          ref={mapRef}
          className={styles.map}
          initial={{ opacity: 0 }}
          style={{ opacity: mapIdle ? opacity : 0 }}
        >
          <Deck
            layers={[pathLayer]}
            viewState={viewState}
            onResize={handleResize}
          >
            <Map
              interactive={false}
              onIdle={() => setMapIdle(true)}
              mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_KEY}
              mapStyle={mapboxStyleUrl}
            />
          </Deck>
        </motion.div>
      </section>
    </>
  )
}

export default VisitMap
