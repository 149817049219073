'use client';
import Markdown from 'components/elements/Markdown';

import { FC, PropsWithChildren } from 'react';
import {
  ComponentSectionsTrainingsIntroduction,
  Product,
} from '__generated__/schema.graphql.types';
import { ArrowUpRight, Timer, UserCheck, Users } from 'lucide-react';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import {
  ResultItem,
  ResultItemBody,
  ResultItemIcon,
  ResultItemTitle,
  Results,
} from '@/components/shared/Results';

interface IntroductionProps {
  data: ComponentSectionsTrainingsIntroduction;
  pageAccentColor?: string;
  associatedProduct: Product;
}

const Postit = (props: PropsWithChildren<{ className: string }>) => {
  return (
    <>
      <div
        className={cn(
          'max-w-md rounded-xl bg-[#FEF3C7] p-6 grid grid-cols-1 auto-rows-auto space-y-2',
          props.className,
        )}
      >
        {props.children}
      </div>
      <style jsx>{`
        div {
          box-shadow:
            0px 36px 47px rgba(0, 0, 0, 0.07),
            0px 10.8529px 14.1691px rgba(0, 0, 0, 0.0456112),
            0px 4.50776px 5.88513px rgba(0, 0, 0, 0.035),
            0px 1.63037px 2.12854px rgba(0, 0, 0, 0.0243888);
        }
      `}</style>
    </>
  );
};

const PostitTitle = ({ children }: PropsWithChildren) => {
  return children ? (
    <h4 className="text-[0.875rem] text-gray-700">{children}</h4>
  ) : null;
};
const PostitBody = ({ children }: PropsWithChildren) => {
  return children ? (
    <div className="text-[0.875rem] text-gray-700 leading-6">{children}</div>
  ) : null;
};
const PostitFooter = ({ children }: PropsWithChildren) => {
  return children ? <div>{children}</div> : null;
};

export const TrainingsIntroduction: FC<IntroductionProps> = ({ data }) => {
  const {
    heading_main,
    heading_results,
    body_main,
    body_results,
    callout,
    results,
  } = data;

  return (
    <section className="container py-16 lg:py-32 lg:pt-24 grid grid-cols-1 lg:grid-cols-[1fr_1fr] gap-x-8 gap-y-10 lg:gap-y-12">
      <div className="col-span-1 lg:col-span-2 mb-0 lg:mb-8">
        <h2 className="text-[1.875rem] my-0 text-slate-700 leading-none font-semibold">
          {heading_main}
        </h2>
      </div>
      <div className="col-span-1">
        <div>
          <Markdown
            content={body_main ?? ''}
            className="text-slate-500 maw-x-2xl lg:max-w-lg leading-7 text-[1rem] space-y-2"
          />
          <Postit className="mt-10 -rotate-3">
            <PostitTitle>{callout?.heading}</PostitTitle>
            <PostitBody>
              <Markdown content={callout?.body ?? ''} className="space-y-2" />
            </PostitBody>
            <PostitFooter>
              <Link
                href={callout?.link?.url ?? ''}
                className="text-gray-900 text-[0.875rem] font-medium inline-flex items-center gap-x-1 hover:border-b-gray-900 hover:border-b"
              >
                {callout?.link?.label}
                <ArrowUpRight className="size-[1.15rem]" />
              </Link>
            </PostitFooter>
          </Postit>
          <h4></h4>
        </div>
      </div>
      <aside className="flex flex-col gap-y-8 col-span-1">
        <div className="flex flex-col gap-y-4">
          <h3 className="text-slate-700 leading-none font-semibold mt-5 lg:mt-0">
            {heading_results}
          </h3>
          <Markdown
            content={body_results ?? ''}
            className="text-slate-500 max-w-lg leading-7 text-[1rem] space-y-2"
          />
        </div>
        <Results>
          {results?.map((result) => {
            return (
              <ResultItem key={result?.id}>
                <ResultItemIcon
                  icon={result?.icon}
                  color={result?.color}
                ></ResultItemIcon>
                <ResultItemTitle>{result?.title}</ResultItemTitle>
                <ResultItemBody content={result?.body ?? ''} />
              </ResultItem>
            );
          })}
        </Results>
      </aside>
    </section>
  );
};
