import styles from 'styles/VisitStatistics.module.scss'
import Markdown from 'components/elements/Markdown'
import { Section } from 'components/Sections'
import { ComponentSectionsVisitStatistics } from '__generated__/schema.graphql.types'
import { FC } from 'react'
import Link from 'next/link'

interface StatisticsColumnProps {
  value: string
  unit: string
  body: string
}

const StatisticsColumn: FC<Partial<StatisticsColumnProps>> = ({ value, unit, body }) => {
  return (
    <article className={styles.statisticsColumn}>
      <h3><span>{value}</span><span>{unit}</span></h3>
      <p>{body}</p>
    </article>
  )
}

interface VisitStatisticsProps extends Section {
  data: ComponentSectionsVisitStatistics
}

const VisitStatistics: FC<VisitStatisticsProps> = ({ data }) => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <article>
          <Markdown content={data.body ?? ''} />
          {data.links?.map(link => {
            return (
              <Link key={link?.id} href={link?.url ?? ''}>
                {link?.label}
              </Link>
            )

          })}
        </article>
        <aside>
          {data.statisticsColumns?.map((column) => {
            const value = column?.value ?? ''
            const unit = column?.unit ?? ''
            const body = column?.body ?? ''

            return <StatisticsColumn key={column?.id} value={value} unit={unit} body={body} />
          })}
        </aside>
      </section>
    </div>
  )
}

export default VisitStatistics
