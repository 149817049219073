import styles from 'styles/ListItemWithIcon.module.scss'
import { FC } from 'react'
import cn from 'classnames'

import { ComponentElementsListItemWithIcon } from '__generated__/schema.graphql.types'
import Markdown from 'components/elements/Markdown'

export interface ListItemWithIconProps extends Omit<ComponentElementsListItemWithIcon, 'id'> {
  id?: string
  direction?: 'row' | 'column'
  className?: string
  type?: 'filled' | 'outlined'
}

const ListItemWithIcon: FC<ListItemWithIconProps> = ({ title, body, icon, color, coloredTitle, className, type = 'filled', direction = 'row' }) => {

  return (
    <div className={cn(styles.container, className, { [styles.column]: direction === 'column' })}>
      <div>
        <span className={styles.materialIcons}></span>
        <style jsx>{`
          span {
            height: 4.25rem;
            width: 4.25rem;
            position: relative;
          }
          span:after {
            content: '${icon}';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${type === 'filled' ? color : 'transparent'};
            outline: 0.1875rem solid ${color};
            color: ${type === 'filled' ? 'white' : color};
          }
        `}</style>
      </div>
      <div className={styles.content}>
        <h4>{title}</h4>
        <style jsx>{`
          h4 {
            color: ${coloredTitle ? color : '#4E5D69'};
          }
        `}</style>
        <Markdown content={body ?? ''}/>
      </div>
    </div>
  )
}

export default ListItemWithIcon

