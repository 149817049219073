'use client';
import Markdown from 'components/elements/Markdown';

import { FC } from 'react';
import {
  ComponentSectionsTrainingsTestimonials,
  Product,
} from '__generated__/schema.graphql.types';
import { TrainingsTestimonial } from '../../shared/TrainingsTestimonial';

interface TestimonialsProps {
  data: ComponentSectionsTrainingsTestimonials;
  pageAccentColor?: string;
  associatedProduct: Product;
}

export const TrainingsTestimonials: FC<TestimonialsProps> = ({ data }) => {
  const { heading, body, kicker, testimonials } = data;

  return (
    <div className="container py-16 lg:py-32 lg:pt-24 grid grid-cols-1 gap-x-8 gap-y-12">
      <div className="mb-8 flex flex-col items-center text-center gap-y-6">
        <span
          className={`font-semibold uppercase text-[0.875rem] text-purple-500`}
        >
          {kicker}
        </span>
        <h2 className="text-[1.875rem] my-0 text-slate-700 leading-none font-semibold">
          {heading}
        </h2>
        <Markdown
          content={body ?? ''}
          className="text-slate-500 max-w-lg leading-7 text-[1rem] space-y-2"
        />
      </div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(5rem,25rem))] auto-rows-max justify-center gap-x-12 gap-y-12">
        {testimonials?.data.map((testimonial) => (
          <div key={testimonial.id}>
            <TrainingsTestimonial {...testimonial} />
          </div>
        ))}
      </div>
    </div>
  );
};
