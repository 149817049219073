import styles from 'styles/VillageOverview.module.scss'
import Markdown from 'components/elements/Markdown'
import StrapiImage from 'components/shared/Image';
import { Section } from 'components/Sections'
import { ComponentSectionsVillageOverview } from '__generated__/schema.graphql.types'
import { FC, useState } from 'react'
import { motion } from 'framer-motion';

interface VillageOverviewProps extends Section {
  data: ComponentSectionsVillageOverview
}

const paths = [
  'M0,128L80,149.3C160,171,320,213,480,213.3C640,213,800,171,960,149.3C1120,128,1280,128,1360,128L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,32L80,64C160,96,320,160,480,192C640,224,800,224,960,192C1120,160,1280,96,1360,64L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,64L80,74.7C160,85,320,107,480,144C640,181,800,235,960,240C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,160L80,149.3C160,139,320,117,480,122.7C640,128,800,160,960,149.3C1120,139,1280,85,1360,58.7L1440,32L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,96L80,85.3C160,75,320,53,480,74.7C640,96,800,160,960,176C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z',
  'M0,192L80,170.7C160,149,320,107,480,112C640,117,800,171,960,181.3C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
]

const VillageOverview: FC<VillageOverviewProps> = ({ data }) => {
  const { title, kicker, body, gallery } = data

  const startingPath = Math.floor(Math.random() * paths.length)
  const [path, setPath] = useState(startingPath)
  const [nextPath, setNextPath] = useState(startingPath+1 >= paths.length ? 0 : startingPath+1)

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <section className={styles.section}>
          <article>
            <span>{kicker}</span>
            <h2>{title}</h2>
            <Markdown content={body ?? ''}/>
          </article>
          <aside>
            {gallery?.data.map((image, index) => (
              <div key={index} style={{ position: 'relative' }}>
                <StrapiImage
                  strapi={image}
                  fill
                  sizes='100vw'
                  style={{
                    objectFit: 'cover',
                  }} />
              </div>
            ))}
          </aside>
        </section>
      </div>
      <motion.div className={styles.wave}>
        <motion.svg viewBox={'0 0 1440 320'}>
          <motion.path
            style={{ fill: '#001122' }}
            initial={{ d: paths[path] }}
            animate={{ d: paths[nextPath] }}
            onAnimationComplete={() => {
              setPath(nextPath)
              const nextIndex = nextPath+1 >= paths.length ? 0 : nextPath+1
              setNextPath(nextIndex)
            }}
            transition={{
              ease: 'easeInOut',
              duration: 10
            }}
          />
        </motion.svg>
      </motion.div>
    </div>
  );
}

export default VillageOverview
