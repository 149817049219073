import css from 'styles/ColumnDouble.module.scss';
import { FC } from 'react';
import { ComponentSectionsColumnDouble } from '__generated__/schema.graphql.types';
import { Section } from 'components/Sections';
import Markdown from 'components/elements/Markdown';
import cn from 'classnames';

interface ColumnDoubleProps extends Section {
  data: ComponentSectionsColumnDouble;
}

const ColumnSingle: FC<ColumnDoubleProps> = ({ data: { firstBody, secondBody }, position }) => {
  return (
    <div className={cn(css.container, { [css.firstElement]: position === 0 })}>
      <div>
        <section>
          <Markdown content={firstBody ?? ''} />
        </section>
        <section>
          <Markdown content={secondBody ?? ''} />
        </section>
      </div>
    </div>
  );
};

export default ColumnSingle;
