import StrapiImage from 'components/shared/Image';
import Markdown from '../elements/Markdown';
import cn from 'classnames';

import css from '../../styles/ProductHeader.module.scss';
import { FC } from 'react';
import {
  ComponentElementsProductHeaderTask,
  ComponentElementsProductHeaderTransaction,
  ComponentSectionsProductHeader,
  Maybe,
  Product,
} from '__generated__/schema.graphql.types';
import ListItemWithIcon, {
  ListItemWithIconProps,
} from 'components/elements/ListItemWithIcon';
import Button from 'components/elements/Button';

interface HeaderProps {
  data: ComponentSectionsProductHeader;
  pageAccentColor?: string;
  associatedProduct: Product;
}

const getListItems = (associatedProduct?: Maybe<Product>) => {
  let listItems: ListItemWithIconProps[] = [];

  if (!!associatedProduct?.duration) {
    listItems = listItems.concat({
      title: 'Kestus',
      body: `${associatedProduct.duration}`,
      icon: 'watch_later',
      color: '#fed233',
      coloredTitle: true,
    });
  }

  if (!!associatedProduct?.players) {
    listItems = listItems.concat({
      title: 'Mängijaid',
      body: `${associatedProduct.players}`,
      icon: 'people',
      color: '#32b300',
      coloredTitle: true,
    });
  }

  if (!!associatedProduct?.price_onsite && !!associatedProduct?.unit_onsite) {
    listItems = listItems.concat({
      title: 'Hind linnakus',
      body: `${associatedProduct.price_onsite} € / ${associatedProduct.unit_onsite}`,
      icon: 'euro',
      color: '#3aa0ff',
      coloredTitle: true,
    });
  }

  if (!!associatedProduct?.ageRange) {
    listItems = listItems.concat({
      title: 'Soovitatud vanus',
      body: `${associatedProduct.ageRange} a`,
      icon: 'emoji_emotions',
      color: '#d95aee',
      coloredTitle: true,
    });
  }

  return listItems;
};

interface AccentColor {
  accentColor: string;
}

const Transaction: FC<
  ComponentElementsProductHeaderTransaction & AccentColor
> = (props) => {
  const { transactionType, amount, title, who } = props;
  return (
    <div className={css.transaction}>
      <div className={cn(css.amount, css[transactionType])}>{amount}</div>
      <h4 className={css.title}>{title}</h4>
      <p className={css.who}>{who}</p>
    </div>
  );
};

const Task: FC<ComponentElementsProductHeaderTask & AccentColor> = (props) => {
  const { accentColor, icon, title } = props;
  return (
    <div className={cn(css.task)}>
      <div className={css.icon}>
        <span className={css.materialIcons}></span>
        <style jsx>{`
          span:after {
            content: '${icon}';
            color: ${accentColor};
          }
        `}</style>
      </div>
      <h4 className={css.title}>{title}</h4>
    </div>
  );
};

const ProductHeader: FC<HeaderProps> = ({
  data,
  pageAccentColor,
  associatedProduct,
}) => {
  const {
    title,
    kicker,
    body,
    heroMedia,
    background,
    links,
    transactions,
    tasks,
  } = data;
  const accentColor =
    associatedProduct?.accentColor ?? pageAccentColor ?? '#3AA0FF';

  const listItems = getListItems(associatedProduct);

  return (
    <>
      <div className={css.container}>
        <header className={css.header}>
          <div className={css.content}>
            <h1>{title}</h1>
            <span className={css.postKicker}>{kicker}</span>
            <Markdown content={body ?? ''} className={css.markdown} />
            {links && (
              <div className={css.buttons}>
                {links.map((link) => (
                  <Button
                    key={link?.id}
                    href={link?.url ?? ''}
                    type={link?.type}
                    openInNew={link?.openInNew}
                    textColor={link?.type === 'inline' ? accentColor : 'white'}
                    normalColor={accentColor}
                  >
                    {link?.label}
                  </Button>
                ))}
              </div>
            )}
          </div>
          <aside className={css.aside}>
            <div className={css.heroImageContainer}>
              <div className={css.transactions}>
                {transactions?.map((tx) => {
                  return tx ? (
                    <Transaction
                      key={tx.id}
                      accentColor={accentColor}
                      {...tx}
                    />
                  ) : null;
                })}
              </div>

              <div className={css.tasks}>
                {tasks?.map((task) => {
                  return task ? (
                    <Task key={task.id} accentColor={accentColor} {...task} />
                  ) : null;
                })}
              </div>

              {heroMedia && (
                <StrapiImage
                  strapi={heroMedia}
                  priority
                  sizes="100vw"
                  width={heroMedia?.data?.attributes?.width ?? 0}
                  height={heroMedia?.data?.attributes?.height ?? 0}
                  style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                    aspectRatio: `${heroMedia?.data?.attributes?.width} / ${heroMedia?.data?.attributes?.height}`,
                  }}
                />
              )}
            </div>
          </aside>
          <div className={cn(css.listItems)}>
            {listItems.map((item) => (
              <ListItemWithIcon
                className={cn(css.listItem)}
                type="outlined"
                direction="row"
                key={item.title}
                {...item}
              />
            ))}
          </div>
        </header>
      </div>
      <style jsx>{`
        header:before {
          background: url('${background?.data?.attributes?.url}');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          z-index: -1;
        }

        h1 {
          color: ${accentColor};
        }
      `}</style>
    </>
  );
};

export default ProductHeader;
