import * as React from 'react';
import { cn } from '@/lib/utils';
import Markdown from 'components/elements/Markdown';
import styles from 'styles/MaterialIcons.module.scss';

// Results
const Results = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('grid grid-cols-1 gap-y-12', className)}
    {...props}
  />
));
Results.displayName = 'Result';

// ResultItem
const ResultItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'grid grid-cols-[auto_1fr] grid-rows-[auto_auto] gap-x-8',
      className,
    )}
    {...props}
  />
));
ResultItem.displayName = 'ResultItem';

// ResultItemIcon
interface ResultItemIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  icon?: string;
  color?: string;
}

const ResultItemIcon = React.forwardRef<HTMLSpanElement, ResultItemIconProps>(
  ({ icon, color, className, ...props }, ref) => (
    <>
      <span
        className={cn(styles.materialIcons, 'col-span-1 row-span-2')}
      ></span>
      <style jsx>{`
        span {
          height: 4rem;
          width: 4rem;
          position: relative;
        }
        span:after {
          content: '${icon}';
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          color: ${color};
        }
        span:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: ${color};
          opacity: 0.15;
          border-radius: 0.75rem;
        }
      `}</style>
    </>
  ),
);
ResultItemIcon.displayName = 'ResultItemIcon';

// ResultItemTitle
const ResultItemTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h4
    ref={ref}
    className={cn(
      'col-span-1 font-semibold text-base leading-7 text-slate-600',
      className,
    )}
    {...props}
  />
));
ResultItemTitle.displayName = 'ResultItemTitle';

// ResultItemBody
interface ResultItemBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string;
}

const ResultItemBody = React.forwardRef<HTMLDivElement, ResultItemBodyProps>(
  ({ content, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('col-span-1 text-slate-500 leading-6 text-base', className)}
      {...props}
    >
      <Markdown content={content} />
    </div>
  ),
);
ResultItemBody.displayName = 'ResultItemBody';

export { Results, ResultItem, ResultItemIcon, ResultItemTitle, ResultItemBody };
