export function getStrapiMedia(url: string) {
  if (url == null) {
    return null
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith("http") || url.startsWith("//")) {
    return url
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
  }${url}`
}

export const getBestMetaImage = ({ src, width, formats }: { src: string, width: number, formats: any }) => {
  const distanceMap = Object.fromEntries(Object.entries(formats)
    .map(
      ([key, value]: [string, any]) => ([Math.abs(width - value?.width), key]),
    ))

  const closestKey = Math.min(...Object.keys(distanceMap).map(key => parseInt(key)))
  const closestFormat = formats[distanceMap[closestKey]]

  return closestFormat?.url ?? src;
};
