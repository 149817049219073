'use client'
import Markdown from 'components/elements/Markdown'
import styles from 'styles/CallToAction.module.scss'
import { getStrapiMedia } from 'lib/assets'
import { Section } from 'components/Sections'
import { ComponentSectionsCallToAction } from '__generated__/schema.graphql.types'
import { FC } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'

interface CallToActionProps extends Section {
  data: ComponentSectionsCallToAction
}

const CallToAction: FC<CallToActionProps> = ({ data }) => {
  const { kicker, title, body, links, background } = data
  // const showBackground = useMediaQuery('(min-width: 950px)')
  const backgroundUrl = getStrapiMedia(background?.data?.attributes?.url ?? '');
  const showBackground = false

  return (
    <div className={styles.container} style={{ backgroundImage: showBackground ? `url(${backgroundUrl})` : 'none' }}>
      <section className={styles.section}>
        <span className={styles.kicker}>{kicker}</span>
        <h2>{title}</h2>
        <Markdown content={body ?? ''} />
        <div className={styles.buttons}>
          {links?.map((link) => {
            const id = link?.id
            const label = link?.label
            const type = link?.type

            return (
              <a key={id} data-type={type} href={link?.url ?? ''}>{label}</a>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default CallToAction

