import css from 'styles/Locations.module.scss'
import { Section } from 'components/Sections'
import StrapiImage from 'components/shared/Image'
import { FC } from 'react'
import cn from 'classnames'
import { ComponentSectionsLocations } from '__generated__/schema.graphql.types'
import Link from 'next/link'

interface LocationProps extends Section {
  data: ComponentSectionsLocations
}

const Location: FC<LocationProps> = ({ data, position }) => {
  const { locations } = data

  return (
    <div className={cn(css.container, { [css.first]: position === 0 })}>
      <section>
        {locations && locations.map(item => {
          const { id, media } = item ?? {}
          const { name, email, phone, address } = item?.location?.data?.attributes ?? {}
          return (
            <article key={id}>
              <aside>
                <StrapiImage
                  strapi={media ?? {}}
                  fill
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center'
                  }}
                />
              </aside>
              <div>
                <h2>{name}</h2>
                <div>
                  <Link href={`mailto:${email}`}>
                    <p>{email}</p>
                  </Link>
                </div>
                <div>
                  <Link href={`callto:${phone}`}>
                    <p>{phone}</p>
                  </Link>
                </div>
                <p>{address}</p>
              </div>
            </article>
          )
        })}
      </section>
    </div>
  );
}

export default Location
