import css from 'styles/ColumnSingle.module.scss'
import { FC } from 'react'
import { ComponentSectionsColumnSingle } from '__generated__/schema.graphql.types';
import { Section } from 'components/Sections';
import Markdown from 'components/elements/Markdown';
import cn from 'classnames'

interface ColumnSingleProps extends Section {
  data: ComponentSectionsColumnSingle
}

const ColumnSingle: FC<ColumnSingleProps> = ({ data: { body }, position }) => {
  return (
    <div className={cn(css.container, { [css.firstElement]: position === 0 })}>
      <section>
        <Markdown content={body ?? ''} />
      </section>
    </div>
  );
}

export default ColumnSingle
