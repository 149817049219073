import {
  ImageLoader,
  ImageLoaderProps,
  default as NextImage,
  ImageProps as NextImageProps,
} from 'next/image';
import { getStrapiMedia } from 'lib/assets';
import {
  UploadFileEntity,
  UploadFileEntityResponse,
} from '__generated__/schema.graphql.types';
import { FC } from 'react';

interface ImageProps extends Omit<NextImageProps, 'src' | 'alt'> {
  strapi: UploadFileEntityResponse | UploadFileEntity;
}

const generateImageLoader = (formats?: any): ImageLoader => {
  if (formats) {
    return ({ src, width }: ImageLoaderProps) => {
      const distanceMap = Object.fromEntries(Object.entries(formats)
      .map(
        ([key, value]: [string, any]) => ([Math.abs(width - value?.width), key]),
      ))

      const closestKey = Math.min(...Object.keys(distanceMap).map(key => parseInt(key)))
      const closestFormat = formats[distanceMap[closestKey]]

      return closestFormat?.url ?? src;
    };
  }

  return ({ src, width }: ImageLoaderProps) => {
    return `${src}?w=${width}`;
  };
};

const Image: FC<ImageProps> = (props) => {
  const { strapi } = props;
  let attributes: UploadFileEntity['attributes'];

  if (strapi.__typename === 'UploadFileEntityResponse') {
    attributes = strapi.data?.attributes;
  }

  if (strapi.__typename === 'UploadFileEntity') {
    attributes = strapi.attributes;
  }

  if (attributes === null || typeof attributes === 'undefined') {
    return null;
  }

  const { url, formats, alternativeText, placeholder, ext } = attributes;
  const imageLoader = generateImageLoader(formats);
  const shouldShowPlaceholder = placeholder && (ext && !['.svg', '.png'].includes(ext))

  return (
    <NextImage
      loader={imageLoader}
      src={getStrapiMedia(url) ?? ''}
      alt={alternativeText ?? ''}
      unoptimized={ext === '.svg' || formats === null}
      {...props}
      {...(shouldShowPlaceholder && { placeholder: 'blur', blurDataURL: placeholder })}
    />
  );
};

export default Image;
