import css from 'styles/FocusTopics.module.scss'
import { FC, useEffect, useRef, useState } from 'react'
import { Section } from 'components/Sections';
import Markdown from 'components/elements/Markdown';
import cn from 'classnames'
import { ComponentElementsListItemWithIcon, ComponentSectionsFocusTopics } from '__generated__/schema.graphql.types';
import { motion } from 'framer-motion';
import useSize from 'hooks/useSize';
import blobshape from 'blobshape';

interface FocusTopicsProps extends Section {
  data: ComponentSectionsFocusTopics
}

const blobOptions = {
  growth: 8,
  edges: 5,
}

const generateBlob = (containerSize: DOMRect) => {
  const { width, height } = containerSize
  const { path } = blobshape({
    size: Math.max(width, height),
    ...blobOptions
  })
  return path
}

const FocusBlob: FC<ComponentElementsListItemWithIcon> = ({ title, body, color, icon }) => {
  const [path, setPath] = useState<string>('')
  const [nextPath, setNextPath] = useState<string>('')
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useSize(containerRef)

  useEffect(() => {
    if (!containerSize) return
    const path = generateBlob({ width: 100, height: 100 } as DOMRect)
    const nextPath = generateBlob(containerSize)
    setPath(path)
    setNextPath(nextPath)
  }, [containerSize])

  return (
    <div className={css.focusBlob}>
      <div
        className={css.iconContainer}
        ref={containerRef}
      >
        <span className={css.materialIcons}></span>
        <style jsx>{`
          span {
            position: relative;
          }
          span:after {
            content: '${icon}';
            color: white;
          }
        `}</style>
        {path && (
          <motion.svg
            whileHover={{
              scale: 1.1,
            }}
            transition={{
              ease: 'linear',
              duration: 0.2
            }}
            viewBox={containerSize && `0 0 ${containerSize?.width} ${containerSize?.height}`}
          >
            <motion.path
              style={{
                fill: `${color}`,
              }}
              initial={{ d: path }}
              animate={{ d: nextPath }}
              transition={{
                ease: 'linear',
                duration: 3
              }}
              onAnimationComplete={() => {
                setPath(nextPath)
                containerSize && setNextPath(generateBlob(containerSize))
              }}
            />
          </motion.svg>
        )}
      </div>
      <div className={css.text}>
        <h3 style={{ color: `${color}` }}>{title}</h3>
        <Markdown className={css.body} content={body ?? ''}/>
        <style jsx>{`
          * {
            --color: ${color};
          }
        `}</style>
      </div>
    </div>
  )
}

const FocusTopics: FC<FocusTopicsProps> = ({ data: { title, body, focusTopics }}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const focusBlobs = focusTopics?.map(focusTopic => {
    return focusTopic? <FocusBlob {...focusTopic} key={focusTopic?.id} /> : null
  })
  return (
    <div className={cn(css.container)}>
      <section>
        <div>
          <h2>{title}</h2>
          <Markdown content={body ?? ''} className={css.markdown}/>
        </div>
        <div className={css.focusBlobContainer} ref={containerRef}>
          {focusBlobs}
        </div>
      </section>
    </div>
  );
}

export default FocusTopics
