import css from 'styles/MediaWithCaption.module.scss'
import { FC } from 'react'
import { ComponentSectionsMediaWithCaption } from '__generated__/schema.graphql.types';
import { Section } from 'components/Sections';
import Markdown from 'components/elements/Markdown';
import StrapiImage from 'components/shared/Image';
import cn from 'classnames'

interface MediaWithCaptionProps extends Section {
  data: ComponentSectionsMediaWithCaption
}

const MediaWithCaption: FC<MediaWithCaptionProps> = ({ data: { singleMedia, caption }, position }) => {
  const attributes = singleMedia?.data?.attributes
  const mime = attributes?.mime

  return (
    <div className={cn(css.container, { [css.firstElement]: position === 0 })}>
      <section>
        {mime?.includes('image') && (
          <div
            className={css.imageContainer}
            style={{
              aspectRatio: `${attributes?.width} / ${attributes?.height}`
            }}
          >
            <StrapiImage
              strapi={singleMedia}
              sizes="100vw"
              fill
            />
          </div>
        )}
        <Markdown className={css.caption} content={caption ?? ''} />
      </section>
    </div>
  );
}

export default MediaWithCaption
